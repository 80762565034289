export const sharedProductSwapping = {
  availability: {
    title: "Availability",
    outofstock: {
      details: {
        estimatedInStock:
          "Estimated in stock in between {{expectedReturnMonth}}-{{additionalReturnMonth}}",
        noReturnDate: "Restock date pending. Check back soon or contact support.",
      },
      description: "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}.",
      wholesaleDescription:
        "This product is currently out of stock. You can still include it in the treatment plan with substitute products attached, so your {{patientTerm}} has alternative options available to purchase while waiting for the preferred product to restock.",
    },
    discontinued: {
      details: "Unavailable",
      description:
        "This product is no longer available, and is currently in {{atRiskPlansTotalCount}} active {{planTerm}}. Swap it across all affected plans now.",
      wholesaleDescription:
        "This product is no longer available. We recommend considering an alternative product to meet your needs.",
    },
    toBeDiscontinued: {
      details: {
        inAFewWeeks: "To be discontinued in a few weeks",
        inTheComingMonths: "To be discontinued in the coming months",
      },
      description:
        "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}. Quickly swap it across all affected plans using the Product substitution tool.",
      wholesaleDescription:
        "This product is scheduled for removal from the Fullscript catalog and will soon be unavailable for purchase. We recommend considering an alternative product to meet your needs.",
    },
    backordered: {
      title: "Delayed",
      details: "Delayed – this item may take longer to ship",
      description:
        "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}. Quickly provide substitutes across all affected plans using the Product substitution tool.",
      wholesaleDescription:
        "This product is currently on backorder. You can still add it to the treatment plan with substitute products attached, allowing your {{patientTerm}} to proceed with their treatment by purchasing an alternative product while awaiting the backordered item.",
    },
    defaultDescription:
      "This product is currently in {{atRiskPlansTotalCount}} active {{planTerm}}.",
  },
  OriginalProductSwapped: "Original product was swapped.",
  ViewOriginalProduct: "View original product",
  OriginalProduct: "Original product",
  dosageInstructions: "Dosage instructions",
  NoDosageInstructions: "No dosage instructions",
  Close: "Close",
  Size: "Size",
  plan: "plan",
  plan_plural: "plans",
  cancel: "Cancel",

  leaveBeforeModal: {
    title: "Your changes will not be saved",
    description: "By leaving you'll lose any progress you’ve started.",
    leave: "Leave",
  },

  drawer: {
    choice: {
      swap: {
        title: "Select replacement",
      },
      substitute: {
        create: {
          title: "Add substitutes",
        },
        update: {
          title: "Edit substitutes",
        },
        addedSuccess: "Substitute product added",
      },
      similarProductsDescription:
        "The suggestions are based on the most similar products available",
      chooseAnotherProduct: "choose another product.",
      chooseOne: "Choose one",
      chooseOneDescription: "The product is unavailable. Select one to replace it with.",
      chooseUpToThree: "Choose up to three",
      chooseUpToThreeDescription: "To avoid purchasing delays, add up to three substitutes.",
      yourSwap: "Your swap:",
      yourSubstitutes: "Your substitutes:",
      searchCatalog: "Search catalog",
      addInstructions: "Add instructions",
      addSubstitute: "Add substitute",
      emptyState: {
        title: "Unavailable product suggestions",
        description:
          "There are currently no available product suggestions. Add your own products by searching the catalog or choose your favorite products.",
        searchCatalog: "Search the catalog",
        favorites: "Favorites",
      },
      disabledComparisonButton: "None of the products can be compared at this time",
      hasDisabledComparisonButton:
        "{{productNames}} can’t be compared due to lack of ingredient information.",
    },
    instructions: {
      originalProduct: "Original product",
      swapDescription: " - This product will be removed from the plan.",
      substituteDescription:
        "The following product will be updated to include {{numberOfSubstitutes}} substitute.",
      // eslint-disable-next-line @typescript-eslint/naming-convention
      substituteDescription_plural:
        "The following product will be updated to include {{numberOfSubstitutes}} substitutes.",
      backToProductSelection: "Back to product selection",
      planSummaryTitle: "Updated plan summary",
      originalInstructions: "Original instructions",
      notifyToPatient: "Email {{patientTermPlural}} their updated plan",
      patient: "{{patientTermCapitalized}}",
      patients: "{{patientTermPluralCapitalized}}",
      replacementProduct: "View replacement product",
    },
  },

  swapMode: {
    selectSwap: "Select swap",
    addSubstitute: "Add substitute",
    originalProduct: "Original product",
    selectedProduct: "{{numberOfSelected}}/{{total}} product selected",
    // eslint-disable-next-line @typescript-eslint/naming-convention
    selectedProduct_plural: "{{numberOfSelected}}/{{total}} products selected",
    swapProducts: "Swap products",
    substituteProducts: "Substitute products",
  },
  updateSuccessMessage: "Your {{patientTerm}} plans have been updated",
  remove: "Remove",
} as const;
